import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { onSendNotification, onSendPushNotification } from '../../util/api';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE, oneSignalMerchantNotificationTemplets } from '../../util/types';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //
export const FETCH_LISTINGS_REQUEST = 'app/WishlistPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/WishlistPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/WishlistPage/FETCH_LISTINGS_ERROR';

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

// ================ Reducer ================ //
const wishlistPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    default:
      return state;
  }
};

export default wishlistPageReducer;



// ================ Action creators ================ //
export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

function generateUniqueId(length = 10) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let uniqueId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters[randomIndex];
  }
  return uniqueId;
}

export const toggleFavorite = (listingId, type, user, authorId, title, images) => (dispatch, getState, sdk) => {
  // Determine the correct wishlist key based on the type of listing
  const wishlistKey =
    type === SERVICE_LISTING_TYPE
      ? 'wishlist_service_ids'
      : type === DEAL_LISTING_TYPE
        ? 'wishlist_deal_ids'
        : type === PRODUCT_LISTING_TYPE
          ? 'wishlist_product_ids'
          : null;

  const userWishlist = user?.attributes?.profile?.publicData?.[wishlistKey] || [];
  // Check if the listing ID is currently in the wishlist
  const isInWishlist = userWishlist.includes(listingId);

  // Create a new wishlist array based on whether the listing ID is already present
  const updatedWishlist = isInWishlist
    ? userWishlist.filter(id => id !== listingId) // Remove the listing ID if it's already in the wishlist
    : [...userWishlist, listingId]; // Add the listing ID if it's not in the wishlist

  // Prepare the updated profile data
  const profileUpdate = {
    publicData: {
      [wishlistKey]: updatedWishlist, // Set the updated wishlist in the profile data
    },
  };

  const firstImage = Array.isArray(images) && images?.length ? images?.[0] : null;

  if (!isInWishlist && authorId) {
    onSendPushNotification({
      userRole: 'customer',
      receiverUserIds: [authorId],
      senderUserId: user?.id?.uuid,
      template_id: oneSignalMerchantNotificationTemplets?.LIKE,
      customData: {
        image: firstImage,
        entityName: type,
        senderName: user?.attributes?.profile?.displayName,
        senderId: user?.id?.uuid,
        actionId: 'id',
        notificationType: "LIKE",
        listingId: listingId,
        isLiked: !isInWishlist,
      }
    });
    
    onSendNotification({
      userId: user?.id?.uuid,
      message: {
        notificationId: generateUniqueId(),
        userRole: 'customer',
        receiverUserIds: [authorId],
        senderUserId: user?.id?.uuid,
        template_id: oneSignalMerchantNotificationTemplets?.LIKE,
        customData: {
          image: firstImage,
          entityName: type,
          senderName: user?.attributes?.profile?.displayName,
          senderId: user?.id?.uuid,
          actionId: 'id',
          isLiked: !isInWishlist,
          notificationType: "LIKE",
          listingId: listingId,
        },
      },
    });
  }

  // Update the user's profile with the new wishlist data
  return dispatch(updateProfile(profileUpdate))
    .then(response => {
      console.log('Profile updated successfully:', response);
      dispatch(fetchCurrentUser());
    })
    .catch(error => console.error('Failed to update profile:', error));
};


// /////////// queryListings //////////////////
export const queryListings = (ids, config) => async (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;

  await dispatch(fetchCurrentUser());

  const { currentUser } = getState().user;
  const { publicData } = currentUser?.attributes?.profile || {};
  const {
    wishlist_deal_ids = [],
    wishlist_product_ids = [],
    wishlist_service_ids = [],
  } = publicData || {};

  const queryParams = {
    ids: ids || wishlist_deal_ids,
    page: 1,
    perPage: 50,
    include: ['author', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  // ////////////
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.listings.query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};


// export const fetchWishlistItems = 

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  return dispatch(queryListings("", config));
};
